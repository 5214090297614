import get from 'lodash/get'
import qs from 'query-string'
// import { fr, es } from 'i18n/config'
import { fr } from 'i18n/config'
import { stripTrailingSlash } from 'utils/job'

export const initialState = {
  location: {},
}

const defaultState = {}
const directoryRoutes = [fr].map(config => config.routes.directory)

const routeMatcher = (route, exact) =>
  new RegExp(`^(/beta)?${route}${exact ? '' : '(/.*)?'}$`)

export const matchesDirectoryRoute = (pathname, { exact = false } = {}) =>
  directoryRoutes.some(
    route => pathname && pathname.match(routeMatcher(route, exact)),
  )

export const getPathname = state =>
  get(state, 'locationBeforeTransitions.location.pathname')
export const getBaseUrl = state => get(state, 'baseUrl')

export const getLastEndpoint = state =>
  stripTrailingSlash(getPathname(state)).split('/').pop()

export const getQuery = state =>
  qs.parse(get(state, 'locationBeforeTransitions.location.search'))
export const getSearch = state =>
  get(state, 'locationBeforeTransitions.location.search')
export const hasHash = state =>
  get(state, 'locationBeforeTransitions.location.hash') !== ''

export const getReferrerPathname = state => get(state, 'referrer.pathname')

export const isHomepage = state =>
  get(state, 'locationBeforeTransitions.location.pathname') === '/'
export const isProHomepage = state =>
  /^\/pro\/?$/.test(get(state, 'locationBeforeTransitions.location.pathname'))
export const isDirectoryHomepage = state =>
  matchesDirectoryRoute(getPathname(state))
export const isDirectoryRoute = state =>
  matchesDirectoryRoute(getPathname(state))
export const getState = state =>
  get(state, 'locationBeforeTransitions.location.state') || defaultState
export const getRedirectPathname = state =>
  get(state, 'locationBeforeTransitions.location.state.redirectPathname')
export const isLandingPage = state =>
  isHomepage(state) || isProHomepage(state) || isDirectoryHomepage(state)
