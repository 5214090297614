/* eslint-disable no-nested-ternary */
import { get, groupBy, pick, partition, findIndex } from 'lodash'

import {
  JOB_PERCEIVED_STATUSES,
  STATUS_PENDING_REALIZATION,
  STATUS_PENDING_RESCHEDULING,
  STATUS_JOB_IN_PROGRESS,
  RECEIPT_SIGNATURE_STATE_BLOCKED,
  VIRTUAL_STATUS_BLOCKED,
  TRACKING_LM_THEME,
  PARTNERS_USING_OVERRIDDEN_MIN_DATE,
} from 'constants/job'
import { getFormattedPhoneNumber } from 'utils/String'
import { createSelector } from 'reselect'
import { addDays, getNextWorkDay } from 'utils/date'

const initJobsCountsReducer = (jobsCounts, statusName) => ({
  ...jobsCounts,
  [statusName]: 0,
})

export const initialState = {
  jobsByIri: null,
  allJobsIsLoading: false,
  jobsCounts: JOB_PERCEIVED_STATUSES.reduce(initJobsCountsReducer, {}),
  currentJobId: null,
  checkConditions: [],
  clientFormPackageConditions: [],
  proPhoneNumber: null,
  receiptTypes: [],
}

export const getJobsByIri = state => state.jobsByIri
export const getJobsByStatus = (state, statuses) => {
  const list = pick(groupBy(state.jobsByIri, 'status'), statuses)
  const result =
    STATUS_PENDING_RESCHEDULING in list
      ? STATUS_PENDING_REALIZATION in list
        ? {
            pending_realization: [
              ...list[STATUS_PENDING_REALIZATION],
              ...list[STATUS_PENDING_RESCHEDULING],
            ],
          }
        : { pending_realization: [...list[STATUS_PENDING_RESCHEDULING]] }
      : list

  if (
    STATUS_JOB_IN_PROGRESS in result &&
    findIndex(result[STATUS_JOB_IN_PROGRESS], [
      'receiptSignatureStatus',
      RECEIPT_SIGNATURE_STATE_BLOCKED,
    ]) !== -1
  ) {
    ;[result[VIRTUAL_STATUS_BLOCKED], result[STATUS_JOB_IN_PROGRESS]] =
      partition(
        result[STATUS_JOB_IN_PROGRESS],
        job => job.receiptSignatureStatus === RECEIPT_SIGNATURE_STATE_BLOCKED,
      )
  }

  return result
}

export const getCustomerJobInfo = state => get(state, 'customerJobInfo')

export const getCustomerReceiptSignableLink = state =>
  get(state, 'customerJobInfo.hoReceiptSignableLink')

export const getCustomerReceiptSignatureDate = state =>
  get(state, 'customerJobInfo.hoReceiptSignatureDate')

export const getProReceiptSignatureDate = state =>
  get(state, 'customerJobInfo.proReceiptSignatureDate')

export const getCustomerPackageName = state =>
  get(state, 'customerJobInfo.packageName')

export const getCustomerPackageShortName = state =>
  get(state, 'customerJobInfo.packageShortName')

export const getCustomerJobStatus = state =>
  get(state, 'customerJobInfo.status')

export const getCustomerRequirementsStatus = state =>
  get(state, 'customerJobInfo.requirements.status')

export const getCustomerInfo = (state, type) =>
  get(state, `customerJobInfo.worksite.order.customer.${type}`)

export const getCustomerFirstName = state =>
  get(state, 'customerJobInfo.worksite.order.customer.firstName')

export const getCustomerLastName = state =>
  get(state, 'customerJobInfo.worksite.order.customer.lastName')

export const getCustomerPhoneNumber = state =>
  get(state, 'customerJobInfo.worksite.order.customer.phoneNumber')

export const getCustomerStreetAddress = state =>
  get(state, 'customerJobInfo.worksite.address.street')

export const getCustomerCity = state =>
  get(state, 'customerJobInfo.worksite.address.city')

export const getCustomerAdress = state => {
  const street = getCustomerStreetAddress(state)
  const city = getCustomerCity(state)

  return street && city ? `${street}, ${city}` : null
}

export const getCustomerPostcode = state =>
  get(state, 'customerJobInfo.worksite.address.postcode')

export const getCustomerType = state =>
  get(state, 'customerJobInfo.worksite.address.type')

export const getCustomerElevator = state =>
  get(state, 'customerJobInfo.worksite.address.elevator')

export const getCustomerParking = state =>
  get(state, 'customerJobInfo.worksite.address.parking')

export const getCustomerAdditionalInformation = state =>
  get(state, 'customerJobInfo.worksite.address.additionalInformation')

export const getCustomerDigicode = state =>
  get(state, 'customerJobInfo.worksite.address.digicode')

export const getCustomerIntercom = state =>
  get(state, 'customerJobInfo.worksite.address.intercom')

export const getCustomerStage = state =>
  get(state, 'customerJobInfo.worksite.address.stage')

export const getCustomerRoom = state =>
  get(state, 'customerJobInfo.worksite.address.room')

export const getCustomerFloor = state =>
  get(state, 'customerJobInfo.worksite.address.floor')

export const getOrderNumber = state =>
  get(state, 'customerJobInfo.worksite.order.orderNumber')

export const getPartnerOrderNumber = state =>
  get(state, 'customerJobInfo.worksite.order.partnerOrderNumber')

export const getPartnerOrderStorePhone = state =>
  getFormattedPhoneNumber(
    get(state, 'customerJobInfo.worksite.order.store.phone'),
  )

export const getPartnerCustomerCareNumber = state =>
  getFormattedPhoneNumber(
    get(state, 'customerJobInfo.worksite.partner.customerCareNumber'),
  )

export const getAcceptedProCompanyName = state =>
  get(state, 'customerJobInfo.acceptedPro.companyName')

export const getAcceptedProFirstName = state =>
  get(state, 'customerJobInfo.acceptedPro.firstName')

export const getAcceptedProLastName = state =>
  get(state, 'customerJobInfo.acceptedPro.lastName')

export const getAcceptedProRankingRating = state =>
  get(state, 'customerJobInfo.acceptedPro.rankingRating')

export const getAcceptedProId = state =>
  get(state, 'customerJobInfo.acceptedPro.id')

export const getProStartTime = state =>
  get(state, 'customerJobInfo.acceptedTimeslot.startTime')

export const getProStopTime = state =>
  get(state, 'customerJobInfo.acceptedTimeslot.stopTime')

export const getTimeslots = state => get(state, 'customerJobInfo.timeslots')

export const getPartnerId = state =>
  get(state, 'customerJobInfo.worksite.partner.id', 1)

export const getWorksiteId = state =>
  get(state, 'customerJobInfo.worksite["@id"]')

export const getJob = (state, iri) => state.jobsByIri[iri]
export const getAllJobsIsLoading = state => state.allJobsIsLoading
export const getJobsCount = (state, status) => state.jobsCounts[status]
export const getJobsCountByStatuses = (state, statuses) =>
  Object.keys(state.jobsCounts)
    .filter(key => statuses.includes(key))
    .reduce((output, key) => {
      // eslint-disable-next-line no-param-reassign
      output[key] = state.jobsCounts[key]
      return output
    }, {})
export const getCurrentJobId = state => state.currentJobId
export const getCurrentJob = state => getJob(state, getCurrentJobId(state))
export const getReceiptCurrentJob = state =>
  getCurrentJob(state) ? getCurrentJob(state).receipt : {}
export const getInvoiceCurrentJob = state =>
  getCurrentJob(state) ? getCurrentJob(state).invoice : {}
export const getReceiptSignatureCurrentJob = state =>
  getCurrentJob(state) ? getCurrentJob(state).receiptSignature : null

export const getEstimatedHour = state =>
  get(state, 'customerJobInfo.estimatedHour')
export const getJobTokenFirebase = state => get(state, 'jobTokenFirebase.token')
export const getGeolocPro = state => get(state, 'geolocPro')
export const getGeolocHO = state =>
  get(state, 'customerJobInfo.worksite.address.point')
export const getEstimatedHourMap = state => get(state, 'estimatedHourMap')
export const getHOCheckinConditions = state => get(state, 'checkConditions')
export const getClientFormPackageCheckinConditions = state =>
  get(state, 'clientFormPackageConditions')
export const getJobPhoneNumber = state => state.proPhoneNumber
export const getAcceptedProUser = state =>
  get(state, 'customerJobInfo.acceptedProUser')
export const getReceiptSignatureStatus = state =>
  get(state, 'customerJobInfo.receiptSignatureStatus')
export const getStatus = state => get(state, 'customerJobInfo.status')
export const getPartner = state =>
  get(state, 'customerJobInfo.worksite.partner.name')
export const getPartnerLabel = state =>
  get(state, 'customerJobInfo.worksite.partner.label')
export const getPartnerLm = state => {
  const partner = getPartner(state)
  return partner === TRACKING_LM_THEME
}
export const getCustomerPartnerMinNbDaysBeforeClientDate = state =>
  get(
    state,
    'customerJobInfo.worksite.partner.publicConfig.min_nb_days_before_client_date',
  )
export const getHoFillInInformationFormError = state =>
  get(state, 'isHoFillInInformationFormError')
export const getJobIri = state => get(state, 'customerJobInfo["@id"]')
export const getJobId = state => get(state, 'customerJobInfo.id')
export const getJobDuration = state =>
  get(state, 'customerJobInfo.worksite.order.duration')
export const getOrderDate = state =>
  get(state, 'customerJobInfo.worksite.order.checkoutCompletedAt')
export const getOrderProducts = state =>
  get(state, 'customerJobInfo.worksite.order.products')
export const getOrderDeliveryDate = state =>
  get(state, 'customerJobInfo.worksite.order.deliveryDate')
export const getOrderProductMaxDeliveryDate = state =>
  get(state, 'customerJobInfo.maxDeliveryDate')
export const getJobPictures = state => get(state, 'customerJobInfo.pictures')

export const getCustomerGender = state =>
  get(state, 'customerJobInfo.customer.gender')
export const getCustomerJobExcludedDates = state =>
  get(state, 'customerJobInfo.excludedDates')
export const getCustomerJobMinDate = state =>
  get(state, 'customerJobInfo.minDate')
export const getRequiredProducts = state => {
  if (getPartnerLm(state) === true) {
    return false
  }

  return get(state, 'customerJobInfo.requiredProducts')
}
export const getReceiptTypes = state => state.receiptTypes

const selectPartnerIsUsingOverriddenMinDate = state =>
  PARTNERS_USING_OVERRIDDEN_MIN_DATE.includes(
    state.customerJobInfo?.worksite?.partner?.id,
  )

export const selectOverriddenMinDate = createSelector(
  [
    selectPartnerIsUsingOverriddenMinDate,
    getCustomerPartnerMinNbDaysBeforeClientDate,
  ],
  (partnerIsUsingOverriddenMinDate, customerPartnerMinNbDaysBeforeClientDate) =>
    partnerIsUsingOverriddenMinDate &&
    Number.isInteger(customerPartnerMinNbDaysBeforeClientDate)
      ? getNextWorkDay(
          addDays(new Date(), customerPartnerMinNbDaysBeforeClientDate),
        )
      : null,
)
